<template>
  <div id="finishedReturnList">
    <el-dialog
      :title="finishedReturnListFormTitle"
      width="1500px"
      :visible.sync="finishedReturnListDialogVisible"
      :close-on-click-modal="false"
      @close="finishedReturnListDialogClose"
    >
      <el-form
        ref="finishedReturnListFormRef"
        :model="finishedReturnListForm"
        :rules="finishedReturnListFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="名称" prop="name">
              <el-input v-model="finishedReturnListForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="涉及批号" prop="batchNo">
              <el-input v-model="finishedReturnListForm.batchNo" placeholder="请输入涉及批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="finishedReturnListForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="涉及数量" prop="quantity">
              <el-input v-model="finishedReturnListForm.quantity" placeholder="请输入涉及数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="12">
                <el-form-item label="退货验收" prop="returnAcceptance">
                  <el-input
                    v-model="finishedReturnListForm.returnAcceptance"
                    placeholder="请输入退货验收"
                    type="textarea"
                    :rows="5"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="库房" prop="warehouseSignature">
                  <el-input v-model="finishedReturnListForm.warehouseSignature" placeholder="请输入库房" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="日期" prop="warehouseSignatureDate">
                  <el-date-picker v-model="finishedReturnListForm.warehouseSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="12">
                <el-form-item label="退货原因及现场检查" prop="returnReason">
                  <el-input
                    v-model="finishedReturnListForm.returnReason"
                    placeholder="请输入退货原因及现场检查"
                    type="textarea"
                    :rows="5"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="质量部" prop="returnReasonQuality">
                  <el-input v-model="finishedReturnListForm.returnReasonQuality" placeholder="请输入质量部" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="日期" prop="returnReasonQualityDate">
                  <el-date-picker v-model="finishedReturnListForm.returnReasonQualityDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="12">
                <el-form-item label="退货处理意见" prop="returnAdvice">
                  <el-input
                    v-model="finishedReturnListForm.returnAdvice"
                    placeholder="请输入退货处理意见"
                    type="textarea"
                    :rows="5"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="质量部" prop="returnAdviceQuality">
                  <el-input v-model="finishedReturnListForm.returnAdviceQuality" placeholder="请输入质量部" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="日期" prop="returnAdviceQualityDate">
                  <el-date-picker v-model="finishedReturnListForm.returnAdviceQualityDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="12">
                <el-form-item label="检验结果分析" prop="testResult">
                  <el-input
                    v-model="finishedReturnListForm.testResult"
                    placeholder="请输入检验结果分析"
                    type="textarea"
                    :rows="5"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="质量部" prop="testResultQuality">
                  <el-input v-model="finishedReturnListForm.testResultQuality" placeholder="请输入质量部" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="日期" prop="testResultQualityDate">
                  <el-date-picker v-model="finishedReturnListForm.testResultQualityDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="12">
                <el-form-item label="退货处理审核" prop="returnReviewQuality">
                  <el-input
                    v-model="finishedReturnListForm.returnReviewQuality"
                    placeholder="请输入退货处理审核"
                    type="textarea"
                    :rows="5"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="质量部" prop="returnReviewQualitySignature">
                  <el-input v-model="finishedReturnListForm.returnReviewQualitySignature" placeholder="请输入质量部" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="日期" prop="returnReviewQualitySignatureDate">
                  <el-date-picker v-model="finishedReturnListForm.returnReviewQualitySignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="12">
                <el-form-item label="退货处理审核" prop="returnReviewFinance">
                  <el-input
                    v-model="finishedReturnListForm.returnReviewFinance"
                    placeholder="请输入退货处理审核"
                    type="textarea"
                    :rows="5"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="财务部" prop="returnReviewFinanceSignature">
                  <el-input v-model="finishedReturnListForm.returnReviewFinanceSignature" placeholder="请输入财务部" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="日期" prop="returnReviewFinanceSignatureDate">
                  <el-date-picker v-model="finishedReturnListForm.returnReviewFinanceSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="12">
                <el-form-item label="（副）总经理审批" prop="generalManagerSignature">
                  <el-input
                    v-model="finishedReturnListForm.generalManagerSignature"
                    placeholder="请输入（副）总经理审批"
                    type="textarea"
                    :rows="5"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="（副）总经理" prop="generalManagerApprove">
                  <el-input v-model="finishedReturnListForm.generalManagerApprove" placeholder="请输入（副）总经理" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="日期" prop="generalManagerSignatureDate">
                  <el-date-picker v-model="finishedReturnListForm.generalManagerSignatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="finishedReturnListForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="finishedReturnListDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="finishedReturnListFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="finishedReturnListPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="batchNo" label="涉及批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="quantity" label="涉及数量" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="finishedReturnListPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addFinishedReturnList, deleteFinishedReturnList, updateFinishedReturnList, selectFinishedReturnListInfo, selectFinishedReturnListList } from '@/api/storage/finishedReturnList'

export default {
  data () {
    return {
      finishedReturnListDialogVisible: false,
      finishedReturnListFormTitle: '',
      finishedReturnListForm: {
        id: '',
        name: '',
        batchNo: '',
        spec: '',
        quantity: '',
        returnAcceptance: '',
        warehouseSignature: '',
        warehouseSignatureDate: '',
        returnReason: '',
        returnReasonQuality: '',
        returnReasonQualityDate: '',
        returnAdvice: '',
        returnAdviceQuality: '',
        returnAdviceQualityDate: '',
        testResult: '',
        testResultQuality: '',
        testResultQualityDate: '',
        returnReviewQuality: '',
        returnReviewQualitySignature: '',
        returnReviewQualitySignatureDate: '',
        returnReviewFinance: '',
        returnReviewFinanceSignature: '',
        returnReviewFinanceSignatureDate: '',
        generalManagerSignature: '',
        generalManagerApprove: '',
        generalManagerSignatureDate: '',
        remarks: ''
      },
      finishedReturnListFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      finishedReturnListPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectFinishedReturnListList(this.searchForm).then(res => {
      this.finishedReturnListPage = res
    })
  },
  methods: {
    finishedReturnListDialogClose () {
      this.$refs.finishedReturnListFormRef.resetFields()
    },
    finishedReturnListFormSubmit () {
      if (this.finishedReturnListFormTitle === '成品退库处理单详情') {
        this.finishedReturnListDialogVisible = false
        return
      }
      this.$refs.finishedReturnListFormRef.validate(async valid => {
        if (valid) {
          if (this.finishedReturnListFormTitle === '新增成品退库处理单') {
            await addFinishedReturnList(this.finishedReturnListForm)
          } else if (this.finishedReturnListFormTitle === '修改成品退库处理单') {
            await updateFinishedReturnList(this.finishedReturnListForm)
          }
          this.finishedReturnListPage = await selectFinishedReturnListList(this.searchForm)
          this.finishedReturnListDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.finishedReturnListFormTitle = '新增成品退库处理单'
      this.finishedReturnListDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFinishedReturnList(row.id)
        if (this.finishedReturnListPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.finishedReturnListPage = await selectFinishedReturnListList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.finishedReturnListFormTitle = '修改成品退库处理单'
      this.finishedReturnListDialogVisible = true
      this.selectFinishedReturnListInfoById(row.id)
    },
    handleInfo (index, row) {
      this.finishedReturnListFormTitle = '成品退库处理单详情'
      this.finishedReturnListDialogVisible = true
      this.selectFinishedReturnListInfoById(row.id)
    },
    selectFinishedReturnListInfoById (id) {
      selectFinishedReturnListInfo(id).then(res => {
        this.finishedReturnListForm.id = res.id
        this.finishedReturnListForm.name = res.name
        this.finishedReturnListForm.batchNo = res.batchNo
        this.finishedReturnListForm.spec = res.spec
        this.finishedReturnListForm.quantity = res.quantity
        this.finishedReturnListForm.returnAcceptance = res.returnAcceptance
        this.finishedReturnListForm.warehouseSignature = res.warehouseSignature
        this.finishedReturnListForm.warehouseSignatureDate = res.warehouseSignatureDate
        this.finishedReturnListForm.returnReason = res.returnReason
        this.finishedReturnListForm.returnReasonQuality = res.returnReasonQuality
        this.finishedReturnListForm.returnReasonQualityDate = res.returnReasonQualityDate
        this.finishedReturnListForm.returnAdvice = res.returnAdvice
        this.finishedReturnListForm.returnAdviceQuality = res.returnAdviceQuality
        this.finishedReturnListForm.returnAdviceQualityDate = res.returnAdviceQualityDate
        this.finishedReturnListForm.testResult = res.testResult
        this.finishedReturnListForm.testResultQuality = res.testResultQuality
        this.finishedReturnListForm.testResultQualityDate = res.testResultQualityDate
        this.finishedReturnListForm.returnReviewQuality = res.returnReviewQuality
        this.finishedReturnListForm.returnReviewQualitySignature = res.returnReviewQualitySignature
        this.finishedReturnListForm.returnReviewQualitySignatureDate = res.returnReviewQualitySignatureDate
        this.finishedReturnListForm.returnReviewFinance = res.returnReviewFinance
        this.finishedReturnListForm.returnReviewFinanceSignature = res.returnReviewFinanceSignature
        this.finishedReturnListForm.returnReviewFinanceSignatureDate = res.returnReviewFinanceSignatureDate
        this.finishedReturnListForm.generalManagerSignature = res.generalManagerSignature
        this.finishedReturnListForm.generalManagerApprove = res.generalManagerApprove
        this.finishedReturnListForm.generalManagerSignatureDate = res.generalManagerSignatureDate
        this.finishedReturnListForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFinishedReturnListList(this.searchForm).then(res => {
        this.finishedReturnListPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFinishedReturnListList(this.searchForm).then(res => {
        this.finishedReturnListPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFinishedReturnListList(this.searchForm).then(res => {
        this.finishedReturnListPage = res
      })
    }
  }
}
</script>

<style>
</style>
